const staticFiles = {
    labelRegulations: '/Regulamin_aktywacji_personalizowane_etykiety_Soplica_2023.pdf',
    lemonContestRegulations: '/Regulamin_Konkursu_Cytrynowe_Polaczenia.pdf',
    weddingContestRegulations: '/Regulamin_Konkursu_Wymarzone_Wesele.pdf',
    akcjaCytrynowkaRegulations:
        '/Regulamin_Konkurs_dla_pracownikow_i_wlascicieli_sklepow_z_zezwoleniem_na_sprzedaz_alkoholu_29.05.2024.pdf',
    summerContestRegulations:
        '/REGULAMIN_KONKURSU_Soplica_rządzi_smakiem_Wybieramy_smak_lata_03.06.2024.pdf',
    zabkaContestRegulations: '/Regulamin_konkursu_Soplica_Klasyka_Gatunku.pdf',
    grajWKlasykeRegulations: '/REGULAMIN_KONKURSU_Soplica_Graj_w_klasykę_14102024.pdf',
    akcjaGorzkaRegulations:
        '/Regulamin_Konkurs_dla_pracowników_i_właścicieli_sklepów_z_zezwoleniem_na_sprzedaż_alkoholu_edycja_Gorzka_2509.pdf',
    halloweenRegulations: '/Regulamin_konkursu_Soplicowe_Halloween_z_Zabka.pdf',
    valentinesRegulations: '/regulamin_konkursu_crush_z_soplica.pdf',
    winterContestRegulations: '/REGULAMIN_KONKURSU_Soplica_zimą_rozgrzewa_smakiem_18112024.pdf',
};

export default staticFiles;
